<template>
    <div class="examinationVisualAcuity">
        <div class="top">
            <div @click="tebs(0)" :class="[teb==0?'backxz':'']">视力检查</div>
            <div @click="tebs(1)" :class="[teb==1?'backxz':'']">佩戴情况</div>
            <div>旧镜佩戴参数测量分析</div>
        </div>
        <div>
            <Homes v-show="teb == 0" ></Homes>
            <Wearing v-show="teb == 1" ></Wearing>
        </div>
        <div class="bottom">
            <el-button class="bt">返回</el-button>
            <el-button class="bt">保存</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "examinationVisualAcuity",
        data() {
          return {
            teb: 0
          }
        },
        components: {
            Homes: () => import('@/components/specialSurveys/examinationVisualAcuityHome.vue'),
            Wearing: () => import('@/views/specialSurvey/wearing.vue')
        },
        methods: {
          tebs(idx) {
            //console.log(idx)
            this.teb = idx
          }
        }
    }
</script>

<style scoped lang="scss">
    .examinationVisualAcuity {
        width: 88vw;
        height: 78vh;
        position: relative;
    }
    .top {
        width: 90%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: start;
        border-bottom: 1px solid #000000;
        div {
            padding: 5px 15px;
            border: 1px solid #000000;
            cursor: pointer;
        }
        div:nth-child(2) {
            border-right: none;
            border-left: none;
        }
       .backxz{background: #eeeeee;}
    }
    .bottom {
        width: 100%;
        position: absolute;
        bottom: 2vh;
        .bt {
            padding-left: 60px;
            padding-right: 60px;
        }
    }
</style>
